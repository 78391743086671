import { ActionConfig } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'

const debug = new Debugger('ADB')
export interface AdeRadioButtonTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Shadow: object
  Background: object
  InnerButton: {
    TextContainer: {
      Text: object
    }
    Release: object
  }
  action?: ActionConfig
  buttonText?: string
}
