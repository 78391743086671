/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AdeErrorPage,
  AdeExitPage,
  AdeLoadingPage,
  AdePlayerPage,
  AdvancedGridDevScreen,
  AuthScreen,
  Debugger,
  GridHome,
  MovieDetailsPage,
  SearchPage,
  SettingsPage,
  ThorApp,
  ThorError,
  TrailerHero,
  TvDetailsPage,
  appErrorWrapper,
  getFirstEpisode,
  getFirstSeason,
  getMovieMedia,
  isGoodArray,
} from '@adiffengine/elements'
import {
  ContentEpisode,
  ContentItem,
  ContentSeason,
  ID,
  MediaDetailsCore,
  PlayerPayload,
} from '@adiffengine/engine-types'

import { Router } from '@lightningjs/sdk'
import { logEvent } from './lib/firebase'
import { getSdk } from './lib/thorStrapiSdk'

const debug = new Debugger('CONF')

async function detailsPageData(
  _type: 'tv' | 'movie',
  id: ID,
  fetcher: (id: ID) => Promise<ContentItem>
): Promise<[boolean, ContentItem]> {
  const promises = [async () => false, fetcher(id)]
  const [inWatchlist, content] = (await Promise.all(promises)) as [
    boolean,
    ContentItem,
  ]
  return [inWatchlist, content]
}

export function getRouteConfig(
  app: ThorApp,
  rootPath: string | null
): Router.Config {
  const root: string = rootPath === null ? 'home' : rootPath
  let fromState = ''
  return {
    root,
    afterEachRoute: async request => {
      logEvent('screen_view', {
        screen_name: request.hash,
      })
      app.stage.application.emit('pageChange', request.hash, fromState)
    },
    beforeEachRoute: async (from, to) => {
      fromState = from
      app.breadcrumb({
        type: 'route_change',
        data: {
          from,
          to,
        },
      })
      if (from) {
        try {
          if (from.indexOf('player') === 0 && to.hash.indexOf('player') !== 0) {
            if (app && (app as any).displayVideo) {
              ;(app as any).displayVideo = false
            }
            if (app && (app as any).$setHeroWidgetImage) {
              ;(app as any).$setHeroWidgetImage(null)
            }
          }
        } catch (error) {
          console.error('Error ensuring player is closed')
        }
      }
      return true
    },

    routes: [
      { path: '$', component: AdeLoadingPage },
      { path: 'loading', component: AdeLoadingPage },
      {
        path: '*',
        component: AdeErrorPage,
        before: async () => {
          app.$currentError(
            new ThorError('Page Not Found', ThorError.Type.NotFoundError)
          )
        },
      },
      {
        path: '!',
        component: AdeErrorPage,
      },
      {
        path: 'error',
        component: AdeErrorPage,
      },
      {
        path: 'exit',
        component: AdeExitPage,
      },
      {
        path: 'search',
        component: SearchPage,
        widgets: ['mainmenu'],
      },
      {
        path: 'settings',
        component: SettingsPage,
        widgets: ['mainmenu'],
      },
      {
        path: 'dev',
        component: AdvancedGridDevScreen,
        before: appErrorWrapper<AdvancedGridDevScreen>(async page => {
          const odinClient = getSdk()
          const allItems = await odinClient.grid({
            params: { type: 'all' },
          })
          page.grid = allItems
        }),
      },
      {
        path: 'link',
        component: AuthScreen,
        widgets: ['mainmenu'],
      },
      {
        path: 'home',
        component: GridHome,
        before: appErrorWrapper<GridHome>(async page => {
          const odinClient = getSdk()
          page.content = await odinClient.grid({
            params: { type: 'all' },
          })
          // page.content = await grid({
          //   params: { type: 'all' },
          // })
        }),
        cache: 1000 * 60 * 15,
        widgets: ['mainmenu'],
        options: {
          clearHistory: true,
        },
      },
      {
        path: 'tv',
        component: TrailerHero,
        before: appErrorWrapper<TrailerHero>(async page => {
          const odinClient = getSdk()
          page.pageRoute = 'tv'
          page.content = await odinClient.grid({
            params: { type: 'tv' },
          })
        }),

        options: {
          reuseInstance: false,
        },
        widgets: ['mainmenu', 'herowidget'],
      },
      {
        path: 'movie',
        component: TrailerHero,
        before: appErrorWrapper<TrailerHero>(async page => {
          const odinClient = getSdk()
          page.pageRoute = 'movie'
          page.content = await odinClient.grid({
            params: { type: 'movie' },
          })
        }),
        options: {
          reuseInstance: false,
        },

        widgets: ['mainmenu', 'herowidget'],
      },
      {
        path: 'details/movie/:id',
        component: MovieDetailsPage,
        options: {
          reuseInstance: false,
        },
        before: appErrorWrapper<MovieDetailsPage>(
          async (page: MovieDetailsPage, params) => {
            const odinClient = getSdk()
            if (!params?.id)
              throw new ThorError(
                'No ID passed to movie details page',
                ThorError.Type.ParameterError,
                { params, page: 'details/movie/:id' }
              )
            const [inWatchlist, content] = await detailsPageData(
              'movie',
              params?.id as ID,
              odinClient.details.bind(odinClient)
            )
            // Set this first to be safer.
            page.inWatchlist = inWatchlist
            page.content = content
          }
        ),
        cache: 1000 * 60 * 15,
      },
      {
        path: 'details/tv/:id',
        component: TvDetailsPage,
        options: {
          reuseInstance: false,
        },

        before: appErrorWrapper<TvDetailsPage>(
          async (page: TvDetailsPage, params) => {
            const odinClient = getSdk()
            if (!params?.id)
              throw new ThorError(
                'No ID passed to TV Details page',
                ThorError.Type.ParameterError,
                { params, page: 'details/tv/:id' }
              )
            const [inWatchlist, content] = await detailsPageData(
              'movie',
              params?.id as ID,
              odinClient.details.bind(odinClient)
            )
            // Set this first to be safer.`
            page.page = `TV Details - content.tile`
            page.inWatchlist = inWatchlist
            page.content = content
          }
        ),
        cache: 1000 * 60 * 15,
      },
      {
        path: 'player/movie/:id',
        component: AdePlayerPage,
        before: appErrorWrapper<AdePlayerPage>(
          async (page: AdePlayerPage, params) => {
            const odinClient = getSdk()
            if (!params?.id)
              throw new ThorError(
                'No ID passed to Movie Player page',
                ThorError.Type.ParameterError,
                { params, page: 'player' }
              )
            const content = await odinClient.details(params.id!)
            const media = getMovieMedia(content)
            page.setPlayerPayload({ content, media })
          }
        ),
        options: { preventStorage: true },
        widgets: ['videoplayer'],
      },
      {
        path: 'player/:type/:id/extra/:extraId',
        component: AdePlayerPage,
        before: appErrorWrapper<AdePlayerPage>(
          async (page: AdePlayerPage, params) => {
            const odinClient = getSdk()
            if (!params?.id)
              throw new ThorError(
                'No ID passed to Movie Player page',
                ThorError.Type.ParameterError,
                { params, page: 'player/:type/:id/extra/:extraId' }
              )
            const content = await odinClient.details(params.id!)

            const media = content.media?.find(
              ({ type, id }) =>
                type === 'extra' &&
                `${id}`.trim() === `${params.extraId}`.trim()
            )
            page.page = `TV Extra Details - ${content.title}`
            if (media) {
              const payload: PlayerPayload = {
                content,
                media,
              }
              page.setPlayerPayload(payload)
            } else {
              throw new ThorError(
                'No Playable Media Found for Content',
                ThorError.Type.NoMedia,
                { content }
              )
            }
          }
        ),
        options: { preventStorage: true },
        widgets: ['videoplayer'],
      },
      {
        path: 'player/tv/:id',
        component: AdePlayerPage,
        beforeNavigate: async (_from, to) => {
          const odinClient = getSdk()
          const url = to.url.replace(/^\//, '').replace(/\/$/, '')
          const id = url.split('/')[2]
          if (id) {
            const content = await odinClient.details(id)
            if (content && isGoodArray(content.seasons)) {
              let first = getFirstSeason(content)
              if (first) {
                if (!Array.isArray(first.episodes)) {
                  first = await odinClient.seasonDetails(
                    content.id,
                    first.number as number
                  )
                }
                const episode = await getFirstEpisode(first)
                if (episode) {
                  return `player/tv/${id}/episode/${first.number}/${episode.episodeNumber}`
                }
              }
            }
          }
          return '*' as any
        },
      },
      {
        path: 'player/tv/:id/episode/:seasonNumber/:episodeNumber',
        component: AdePlayerPage,
        before: appErrorWrapper<AdePlayerPage>(
          async (page: AdePlayerPage, params = {}) => {
            const odinClient = getSdk()
            if (!params?.id || !params.seasonNumber)
              throw new ThorError(
                'No ID passed or Season to Movie Episode Player page',
                ThorError.Type.ParameterError,
                {
                  params,
                  page: 'player/tv/:id/episode/:seasonNumber/:episodeNumber',
                }
              )

            const promises: [
              Promise<ContentEpisode | null>,
              Promise<ContentSeason | null>,
              Promise<ContentItem | null>,
            ] = [
              odinClient.episodeDetails(params.id),
              odinClient.seasonDetails(params.id, params.seasonNumber),
              odinClient.details(params.id),
            ]
            const [episode, season, content] = await Promise.all(promises)
            if (content && episode) {
              if (isGoodArray<MediaDetailsCore>(episode.media)) {
                const media = episode.media.find(
                  ({ type }) => type === 'episode'
                )
                if (media) {
                  page.setPlayerPayload({
                    content,
                    media,
                    season: season ?? undefined,
                    episode,
                  })
                  page.page = [
                    'TV Episode / Season Details',
                    content.title,
                    season
                      ? `Season: ${season.number ?? season.id}`
                      : episode.name,
                  ].join(' - ')
                  return
                }
              }
            }
            throw new ThorError(
              'No episode details found',
              ThorError.Type.NotFoundError,
              { params }
            )
          }
        ),
        options: { preventStorage: true },
        widgets: ['videoplayer'],
      },
    ],
  }
}
