/* eslint  @typescript-eslint/no-explicit-any: 0 */
import { Settings } from '@lightningjs/sdk'
import isString from 'lodash-es/isString'
const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`

export class Debugger {
  private _flag = ''
  private _color: string
  private _messageCount = 10
  private _remindThrottle = 10
  private _tags: string[] = []
  private _enabled: boolean | null = null
  set disabled(disable: boolean) {
    this.enabled = !disable
  }
  get disabled() {
    return !this.enabled
  }
  set enabled(enabled: boolean) {
    const env = Settings.get('app', 'environment', 'production')
    if (env !== 'production' && env !== 'staging') {
      this._enabled = enabled
    }
  }
  static _focused: string[] = []
  static setFocused(focus: string) {
    this._focused.push(focus)
  }
  static isFocused(flag: string, tags: string[] = []) {
    if (this._focused.length === 0) return true
    return this._focused.reduce((acc, focus) => {
      return acc || flag === focus || tags.includes(focus)
    }, false)
  }
  get enabled(): boolean {
    const env = Settings.get('app', 'environment', 'production')
    const debug = Settings.get('app', 'debug', env !== 'production')
    const isFocused =
      debug === true && Debugger.isFocused(this._flag, this._tags)

    return (
      env !== 'production' && env !== 'staging' && debug === true && isFocused
    )
  }
  tag(tag: string) {
    this._tags.push(tag)
  }
  constructor(flag?: string) {
    if (isString(flag)) this._flag = `[ ${flag} ]: `
    this._color = randomColor()
  }
  get flag() {
    return `${this._tags.length > 0 ? '[ ' + this._tags.join('], [') + ' ] ' : ''} ${this._flag}`
  }
  info(s: any, ...args: any[]) {
    if (this.enabled) {
      console.info(`%c${this.flag}${s}`, `color: ${this._color}`, ...args)
    } else {
      this._messageCount++
      if (
        this._messageCount >= this._remindThrottle &&
        this._enabled !== null
      ) {
        console.info(
          `%cJust a reminder that ${this._flag} is disabled `,
          `color: ${this._color}`
        )
      }
      this._messageCount = 0
    }
  }

  warn(s: any, ...args: any[]) {
    if (this.enabled) {
      console.warn(`${this._flag}${s}`, ...args)
    }
  }
  error(s: any, ...args: any[]) {
    if (this.enabled) {
      console.error(`${this._flag}${s}`, ...args)
    }
  }
  log(s: any, ...args: any[]) {
    this.info(s, ...args)
  }
}
