import { ImageSet } from '@adiffengine/engine-types'

const TmdbImageSizes = {
  backdrop: ['w300', 'w780', 'w1280', 'original'],
  logo: ['w45', 'w92', 'w154', 'w185', 'w300', 'w500', 'original'],
  poster: ['w92', 'w154', 'w185', 'w342', 'w500', 'w780', 'original'],
}
const baseTmdbImageUrl = 'https://image.tmdb.org/t/p/'
export class TMDBImageSet implements ImageSet {
  public readonly baseUrl: string
  public readonly sizes: string[]
  public readonly path: string
  public readonly sized: Record<number, string>
  private _original: boolean

  static tmdbImageSetFromPath(type: keyof typeof TmdbImageSizes, path: string) {
    const sizes = TmdbImageSizes[type]
    return new TMDBImageSet(path, sizes, baseTmdbImageUrl)
  }

  constructor(path: string, sizes: string[], baseUrl: string) {
    this.path = path
    this.baseUrl = baseUrl
    this.sizes = sizes
    this._original = false
    this.sized = sizes.reduce(
      (out, size) => {
        if (/^w[0-9]+$/.test(size)) {
          out[parseInt(size.replace(/^w/, ''), 10)] = size
        } else if (size === 'original') {
          this._original = true
        }
        return out
      },
      {} as Record<number, string>,
    ) as Record<number, string>
  }

  getUrl(size: string) {
    if (!this.path || !this.baseUrl) return null
    return `${this.baseUrl}${size}${this.path}`
  }
  getForWidth(targetWidth: number) {
    if (!this.sized || !Object.keys(this.sized).length) return null
    const widths = Object.keys(this.sized)
      .map(Number)
      .sort((a: number, b: number) => a - b)
    const bigger = widths.filter(f => f >= targetWidth).sort((a, b) => a - b)
    const smaller = widths.filter(f => f < targetWidth).sort((a, b) => a - b)
    const final = bigger.length ? bigger[0] : smaller[smaller.length - 1]
    return this.getUrl(this.sized[final])
  }
  getBiggest() {
    if (this._original) return this.getUrl('original')
    else if (Object.keys(this.sized).length) {
      return this.sized[
        Object.keys(this.sized)
          .map(Number)
          .sort((a, b) => a - b)[Object.keys.length - 1]
      ]
    } else if (this.sizes.length) {
      return this.getUrl(this.sizes[this.sizes.length - 1])
    } else return null
  }

  toJSON() {
    return {
      sizes: this.sizes,
      baseUrl: this.baseUrl,
      path: this.path,
    }
  }
  get original() {
    return this._original
  }
}
