import {
  AdePlayerEventMap,
  ContentEpisode,
  ContentItem,
  ContentSeason,
  ContentSource,
  MediaDetails,
  MediaDetailsExtra,
  PlayerPayload,
  PlayerState,
  SlowPlayerState,
  TrickModeType,
  VideoEvent,
  isEpisodePlayerPaylaod,
  isExtra,
} from '@adiffengine/engine-types'

import { Colors, Lightning, Registry, Router, Settings } from '@lightningjs/sdk'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { MetroPlayer } from '@adiffengine/vast-player'
import { AdLoader, AdsEvents, getAdLoader } from '@adiffengine/video-enhancers'
import { UnsubscribeFunction } from 'emittery'
import isEqual from 'fast-deep-equal/es6'
import defer from 'lodash-es/defer'
import isDate from 'lodash-es/isDate'
import isFunction from 'lodash-es/isFunction'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'
import {
  Debugger,
  PointerHelper,
  ThorError,
  backHandler,
  getExtension,
  isGoodArray,
  isGoodNumber,
} from '../../lib'
import { AdvancedBoxCard, AdvancedGridCardTypes } from '../AdvancedGrid'
import { NextVideoCard } from '../NextVideoCard'
import { AdePlayControls } from './AdePlayControls'
import { PlayerMore } from './PlayerMore'
import { Progress as ProgressComponent } from './Progress'
import { unloader } from './lib/hls'
import { optsLoader } from './lib/optsLoader'
import { EventTypes, PlayerEventPayload } from './lib/playerEvents'
import { LoaderOpts, VideoDetails, VideoLoader } from './lib/types'

const debug = new Debugger('PlayerPlane')

export interface PlayerPlaneTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Wrapper: {
    Vignette: object
    Content: {
      UpNext: typeof NextVideoCard
      // PlayControls: typeof AdePlayControls
      Progress: typeof ProgressComponent
    }
    MoreVignette: object
    More: typeof PlayerMore
  }
  videoSrc: string | null
  moreCardType: AdvancedGridCardTypes
}

export interface VideoPage extends Router.PageInstance {
  _handleBack?(): void
}

export interface OptsLoaderOptions {
  adUrl: string
}

export interface LoaderLookup {
  loader: VideoLoader
  unloader?: typeof unloader
}
const visualLoader = async (url: string, videoEl: HTMLVideoElement) => {
  videoEl.setAttribute('crossOrigin', 'anonymous')
  videoEl.setAttribute('src', url)
  videoEl.load()
}

function getLoader(
  src: string,
  details: VideoDetails = {},
  extra: LoaderOpts
): LoaderLookup | null {
  const extension = getExtension(src)
  if (!extension) return { loader: optsLoader(details, extra), unloader }
  switch (extension) {
    case 'm3u8':
      return { loader: optsLoader(details, extra), unloader }
    case 'mp3':
      return { loader: visualLoader }
    default:
      return null
  }
}

export interface AdePlayerPlaneTypeConfig
  extends Lightning.Component.TypeConfig {
  EventMapType: AdePlayerEventMap
}

export class AdePlayerPlane
  extends Lightning.Component<PlayerPlaneTemplateSpec, AdePlayerPlaneTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<PlayerPlaneTemplateSpec>
{
  _videoSrc: string | null = null
  static offPage: number = PlayerMore.height + 80 - PlayerMore.tabHeight
  static contentHeight: number =
    AdePlayControls.height + ProgressComponent.height + 20
  private _hideAnimation: Lightning.types.Animation | null = null
  private _showMoreAnimation: Lightning.types.Animation | null = null
  private _hideable = true
  private _content: ContentItem | null = null
  _media: MediaDetails | null = null
  private _extra: MediaDetailsExtra | null = null
  private _episode: ContentEpisode | null = null
  private _season: ContentSeason | null = null
  private _subscriptions: UnsubscribeFunction[] = []
  private _timeout: ReturnType<typeof Registry.setTimeout> | null = null
  MediaPlayer = MetroPlayer
  static defaultPlayerState: PlayerState = {
    canPlay: false,
    content: null,
    ended: false,
    paused: true,
    currentTime: 0,
    duration: 0,
    adActive: false,
    error: null,
    controlsHidden: false,
  }

  private _playerState: PlayerState = AdePlayerPlane.defaultPlayerState

  Wrapper = this.getByRef('Wrapper')!
  Content = this.Wrapper.getByRef('Content')!
  More = this.Wrapper.getByRef('More')!
  Progress = this.Content.getByRef('Progress')!
  UpNext = this.Content.getByRef('UpNext')!

  static override _template(): Lightning.Component.Template<PlayerPlaneTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080 + this.offPage,
      w: 1920,
      Wrapper: {
        x: 0,
        y: 0,
        w: 1920,
        h: (x: number) => x,
        Vignette: {
          y: 1080 / 2,
          x: 0,
          w: 1920,
          h: 1080 / 2,
          rect: true,
          rtt: true,
          colorTop: Colors('black').alpha(0).get(),
          colorBottom: Colors('black').alpha(0.8).get(),
        },

        Content: {
          alpha: 1,
          x: 280,
          h: this.contentHeight,
          w: 1920 - 560,
          y: 1080 - PlayerMore.tabHeight - 80 - 40 - this.contentHeight,
          UpNext: {
            x: 1920 - 560 - NextVideoCard.width,
            y: (h: number) =>
              h - NextVideoCard.height - ProgressComponent.height - 40,
            type: NextVideoCard,
            signals: {
              next: 'nextVideo',
              left: '_focusPlayControls',
            },
          },
          Progress: {
            type: ProgressComponent,
            x: 0,
            y: (h: number) => h - ProgressComponent.height,
            w: (w: number) => w,
            h: ProgressComponent.height,
            signals: {
              down: '_downFromPlayerControls',
              hovered: '_focusPlayControls',
            },
          },
        },
        MoreVignette: {
          y: 1080,
          x: 0,
          h: PlayerMore.height,
          w: 1920,
          rect: true,
          rtt: true,
          color: Colors('black').alpha(0.8).get(),
        },
        More: {
          visible: false,
          x: 0,
          y: 1080 - 80 - PlayerMore.tabHeight,
          w: 1920,
          h: PlayerMore.height,
          type: PlayerMore,
          signals: {
            up: '_focusPlayControls',
            showMore: '_showMore',
            hideMore: '_hideMore',
            hovered: '_downFromPlayerControls',
          },
        },
      },
    }
  }

  /******
   *
   *  Start: Content Setup
   *
   *  Everything comes into player payload and get set from there.
   *  Note there are a few things that are order dependent
   *
   *  Set content first and it resets all the UI
   *
   *  Set episode before season.
   */
  private _page: VideoPage | null = null
  override _onActivated(page: VideoPage) {
    const playerPayload = this.fireAncestors('$getPlayerPayload')
    if (playerPayload) {
      this.setPlayerPayload(playerPayload)
    }
    this._page = page
  }

  private _moreCardType: AdvancedGridCardTypes = AdvancedBoxCard
  set moreCardType(card: AdvancedGridCardTypes) {
    if (card !== this._moreCardType) {
      this._moreCardType = card
      this.More.patch({
        cardType: this.moreCardType,
      })
    }
  }

  get moreCardType() {
    return this._moreCardType
  }
  $clearPlayer() {
    this.setPlayerPayload(null)
  }
  private __trickModeActive: TrickModeType = null

  set _trickModeActive(mode: TrickModeType) {
    if (mode !== this.__trickModeActive) {
      this.__trickModeActive = mode
      this.stage.application.emit('activeTrickMode', mode)
      if (mode === null) this.hideable = true
      else this.hideable = false
    }
  }

  $activeTrickMode(): TrickModeType {
    return this.__trickModeActive
  }
  $setActiveTrickMode(mode: typeof this.__trickModeActive): void {
    this._trickModeActive = mode
  }

  setPlayerPayload(p: PlayerPayload | null) {
    if (p) {
      const { media, content } = p
      this.content = content // Do this first, it resets everything.
      this.media = media ?? null
      if (isEpisodePlayerPaylaod(p)) {
        this.episode = p.episode
        this.season = p.season ?? null
      }
    } else {
      this.content = null
      this.MediaPlayer.close()
      this.updateTime(0, 0)
      this.updateState(AdePlayerPlane.defaultPlayerState)
    }
  }

  set content(item: ContentItem | null) {
    if (
      (item === null && this.content === null) ||
      (this.content && item && this.content.id === item.id)
    ) {
      debug.info('No Content Item')
    } else {
      this._nextVideo = null
      this.media = null
      this.extra = null
      this.season = null
      this._setState('Default')
      this.More._reset()
      this._content = item
      this.stage.application.emit('playerContentItemChanged', item)
      this.startTimer()
      this.More._setState('DownState')
      console.info('Setting new content', item)
      if (item && isGoodArray(item.similar)) {
        console.info('Got Similar', item.similar)
        this.More.patch({
          visible: true,
          more: item.similar,
          cardType: this.moreCardType,
        })
      } else {
        this.More.patch({
          more: [],
          visible: false,
        })
      }
      this.Progress.patch({ title: item ? item.title : '' })
      this._refocus()
      if (item !== null) this.loadNext()
    }
  }
  get content() {
    return this._content
  }

  set media(media: MediaDetails | null) {
    if (!isEqual(media, this._media)) {
      this._media = media
      this.MediaPlayer.close()
      if (media !== null) {
        if (media !== null) {
          if (isGoodArray<ContentSource>(media.sources)) {
            const source = this.fireAncestors(
              '$getAppropriateSource',
              media.sources
            ) as ContentSource | null
            if (source) {
              this.videoSrc = source.src
            }
          }
        }
        if (isExtra(media)) {
          this.extra = media
        }
      }
      this.stage.application.emit('playerMediaChanged', media)
    }
  }

  get media() {
    return this._media
  }

  set extra(extra: MediaDetailsExtra | null) {
    if (!isEqual(this._extra, extra)) {
      this._extra = extra
      this.Progress?.patch({
        extra: extra?.title ?? '',
      })
    }
  }
  get extra() {
    return this._extra
  }

  set episode(episode: ContentEpisode | null) {
    this._episode = episode
    if (episode) {
      const episodeTextParts: string[] = []
      if (isString(episode.name)) episodeTextParts.push(episode.name)
      if (episode.season != null && episode.episodeNumber != null)
        episodeTextParts.push(
          `Season ${episode.season} Episode ${episode.episodeNumber}`
        )
      if (episodeTextParts.length > 0) {
        this.Progress.patch({
          extra: episodeTextParts.join(' - '),
        })
      }
    }
  }
  get episode() {
    return this._episode
  }

  set season(season: typeof this._season) {
    this._season = season
    if (season !== null) {
      let { episodes = [], ...rest } = season
      episodes = episodes === null ? [] : episodes
      const filtered = episodes.filter(
        e =>
          this.episode &&
          e.episodeId !== this.episode.episodeId &&
          (!isDate(e.air_date) || e.air_date.getTime() < new Date().getTime())
      )
      this.More.patch({
        season:
          filtered.length > 0 ? { ...rest, episodes: filtered } : undefined,
      })
    } else {
      this.More.patch({
        season: undefined,
      })
    }
  }
  get season() {
    return this._season
  }

  /*
   *
   *   End Content Setup
   *
   ********************************************/

  /****************
   *  Player Setup and Events
   *
   *
   */
  set videoSrc(src: string | null) {
    if (src && src !== this.MediaPlayer.src) {
      this._videoSrc = src
      this._startVideo()
    }
  }

  get videoSrc() {
    return this._videoSrc
  }
  playerSetup() {
    this.MediaPlayer.position(0, 0)
    this.MediaPlayer.size(this.w, this.h)
  }
  override _enable() {
    this.stage.application.on('playerControlsShouldHide', this._enableHide)
    const playerState = this.fireAncestors('$getPlayerState')
    this.watchControls(playerState)
    this.stage.application.on('playerState', this.watchControls)
    this.fireAncestors('$monitorVideos', true)
  }

  override _disable() {
    this.stage.application.off('playerState', this.watchControls)
    this.stage.application.off('playerControlsShouldHide', this._enableHide)
    this._adManager?.destroy()
    this.fireAncestors('$monitorVideos', false)
  }

  override _firstActive(): void {
    this.MediaPlayer.consumer(this)
    this.MediaPlayer.size(1920, 1080)
  }
  private _pointerInstance: PointerHelper | null = null
  private get _pointerHelper(): PointerHelper | null {
    const enablePointer = Settings.get('app', 'enablePointer', false)
    if (this._pointerInstance === null && enablePointer) {
      this._pointerInstance = new PointerHelper()
    }
    return this._pointerInstance
  }

  clearAdSubscriptions() {
    this._subscriptions.forEach(s => s())
    this._subscriptions = []
  }

  private _adManager: AdLoader | null = null

  async _startVideo() {
    if (!this._videoSrc) {
      console.warn('Tried to start video with no video source or media')
      return
    }
    const adUrl = await this.fireAncestors('$adTagForContent', this.content)
    const loaders = getLoader(
      this._videoSrc,
      {
        media: this._media ?? undefined,
        content: this.content ?? undefined,
      },
      { vastUrl: adUrl ? adUrl : undefined }
    )

    this.MediaPlayer.close()
    this.clearAdSubscriptions()

    if (loaders !== null) {
      const { loader, unloader = null } = loaders
      this.MediaPlayer.loader(loader)
      if (unloader) this.MediaPlayer.unloader(unloader)
    }

    if (adUrl) {
      this._adManager = await getAdLoader(
        this.MediaPlayer._videoEl,
        Settings.get('app', 'ADS_LOADER', 'ima')
      )
      this._adManager?.onAll(this.adEvent)
      debug.info('Ad Loader', this._adManager)
    } else {
      this._adManager = null
    }
    //TODO: Get from the content item
    this.MediaPlayer.open(this.videoSrc)
    if (this._adManager) {
      this.MediaPlayer.pause()
      const adUrl = await this.fireAncestors('$adTagForContent', this.content)

      if (adUrl !== null) {
        await this._adManager.requestAds(adUrl)
      }
    }
  }

  async loadNext() {
    if (this.content) {
      this._nextVideo = await this.fireAncestors('$nextVideo', this.content)
      debug.info('Next Video', this._nextVideo?.title)
      this.UpNext.next = this._nextVideo || null
    }
  }
  private _nextVideo: ContentItem | null = null
  async nextVideo(): Promise<boolean> {
    let next = this._nextVideo
    if (next === null) {
      next = await this.fireAncestors('$nextVideo', this.content)
    }
    const userState = this.fireAncestors('$userState')

    debug.info('Next and user state', next, userState)
    if (next && userState === 'active') {
      this.updateVideo(next)
      return true
    } else {
      return false
    }
  }

  async previousVideo() {
    const previous = await this.fireAncestors('$previousVideo', this.content)
    this.updateVideo(previous)
  }

  updateVideo(item: ContentItem | null) {
    if (item) {
      this.$videoPlayerClear()
      this.fireAncestors('$navigate', item.paths.player, false)
    } else {
      this._captureBack()
    }
  }

  private _upNextActive = false
  $disableHidePlayControls(active?: boolean) {
    if (active !== undefined) {
      this._upNextActive = active
      this.upNextActive = !active
      return active
    } else {
      return this._upNextActive
    }
  }

  updateState(state: Partial<SlowPlayerState>) {
    const newState: PlayerState = { ...this._playerState, ...state }
    if (!isEqual(newState, this._playerState)) {
      this._playerState = newState
      this.stage.application.emit('playerState', this._playerState)
    }
  }
  updateTime(time: number, duration: number) {
    this.stage.application.emit('playerTime', time, duration)
  }
  _lastError: MediaError | null = null
  $videoPlayerEvent(event: EventTypes, eventData: PlayerEventPayload) {
    if (eventData) {
      const { videoElement } = eventData
      let currentError: MediaError | null = null
      if (videoElement.error && videoElement.error !== this._lastError) {
        this._lastError = videoElement.error
        currentError = videoElement.error
      } else if (videoElement.error == null && this._lastError != null) {
        this._lastError = null
      }
      const current: Partial<SlowPlayerState> = {
        paused: videoElement.paused,
        duration: isNumber(videoElement.duration)
          ? videoElement.duration
          : Infinity,
        error: currentError,
      }
      if (current.error) {
        const e = new ThorError(
          current.error.message,
          ThorError.Type.PlaybackError,
          {
            content: this.content,
            error: current.error,
          }
        )
        this.fireAncestors('$error', e)
        this.stage.application.emit('playerError', e)
        this.fireAncestors('$error', e)
      }
      this.updateState(current)
    }
    switch (event.toLowerCase()) {
      case 'ended':
        this.updateState({ ended: true })
        break
      case 'timeupdate': {
        if (eventData) {
          const { currentTime, duration } = eventData.videoElement
          const time = isGoodNumber(currentTime) ? currentTime : 0
          const d = isGoodNumber(duration) ? duration : Infinity
          this.updateTime(time, d)
        }
        this.updateState({ canPlay: true })
        break
      }
      case 'canplay':
        this.updateState({ canPlay: true })
        this.startTimer()
        break
      case 'pause':
        this.clearTimer()
        this.showUi()
        break
      case 'play':
        if (this._playerState.canPlay) {
          this.startTimer()
        }
        break
    }
    this.trackEvent(event, eventData)
  }

  adStarted() {
    return this.updateState({ controlsHidden: true, adActive: true })
  }
  allAdsCompleted() {
    return this.updateState({ controlsHidden: false, adActive: false })
  }

  adEvent<T extends keyof AdsEvents>(event: T, payload: AdsEvents[T]) {
    debug.info('Got Ad Event: %s', event, payload)
    switch (event) {
      case 'adStarted':
        return this.updateState({ controlsHidden: true })
      case 'allAdsComplete':
        return this.updateState({ controlsHidden: false })
      case 'onPauseRequested':
        debug.info('Pause Requested, calling Pause')
        return this.MediaPlayer.pause()
      case 'onResumeRequested':
        debug.info('Resume Requested, calling Play')
        return this.MediaPlayer.play()
    }
  }

  async $videoPlayerEnded() {
    const hasNext = await this.nextVideo()
    debug.info('$videoPlayerEnded', hasNext)
    if (!hasNext) {
      backHandler(this.application)
    }
  }

  $videoPlayerClear() {
    this.updateState(AdePlayerPlane.defaultPlayerState)
  }

  _handlePlay() {
    if (!this.MediaPlayer.playing) {
      this.MediaPlayer.play()
    }
  }
  _handlePause() {
    if (this.MediaPlayer.playing) {
      this.MediaPlayer.pause()
    }
  }

  override _handlePlayPause() {
    this.MediaPlayer.playPause()
  }
  override _handleFastForwardRelease() {
    this.$setActiveTrickMode(null)
  }
  override _handleFastForward() {
    this.$setActiveTrickMode('fastforward')
  }
  override _handleRewind() {
    this.$setActiveTrickMode('rewind')
  }
  override _handleRewindRelease() {
    this.$setActiveTrickMode(null)
  }

  override _handleKey(e: KeyboardEvent) {
    const env = Settings.get('app', 'environment')
    if (env !== 'production') {
      switch (e.code) {
        case 'KeyE':
          const duration = isNaN(this.MediaPlayer.duration)
            ? null
            : this.MediaPlayer.duration
          if (duration !== null) {
            this.MediaPlayer.seek(duration - 5)
          }
      }
    }
  }

  stopPlayback() {
    this.MediaPlayer.close()
  }

  get hasMore() {
    return this.More.visible === true
  }

  _focusPlayControls() {
    debug.info('Focus Play Controls', this)
    this.currentFocus = this.Progress
  }
  _downFromPlayerControls() {
    if (this.More.visible === true) {
      this.currentFocus = this.More
    }
  }
  _rightFromPlayerControls() {
    this.currentFocus = this.UpNext
  }

  override _getFocused() {
    return this.currentFocus
  }

  private _currentFocus:
    | typeof this.More
    | typeof this.Progress
    | typeof this.UpNext = this.Progress

  set currentFocus(focus: typeof this._currentFocus) {
    if (focus !== this._currentFocus) {
      this._currentFocus = focus
      this._refocus()
    }
  }
  get currentFocus() {
    return this._currentFocus
  }

  clear() {
    this.MediaPlayer.clear()
  }

  $videoState() {
    return this._playerState
  }
  $playerControl(event: 'play' | 'pause' | 'togglePaused'): any {
    switch (event) {
      case 'pause':
        return this.MediaPlayer.pause()
      case 'play':
        return this.MediaPlayer.play()
      case 'togglePaused':
        debug.info('Calling PlayPause', this.MediaPlayer.playPause)
        return this.MediaPlayer.playPause()
    }
  }
  $jumpToTime(time: number) {
    debug.info('Jump to time %s', this.MediaPlayer.duration)
    if (time >= 0 && time <= this.MediaPlayer.duration) {
      this.MediaPlayer.seek(time)
    }
  }

  $skip(arg: number) {
    return this.MediaPlayer.skip(arg)
  }

  get paused() {
    return !this.MediaPlayer.playing
  }

  override _inactive() {
    this.stage.application.emit('playerContentItemChanged', null)
    this.clearTimer()
  }

  override _captureBack() {
    debug.info('Capture Back', this._page)
    if (isFunction(this._page?._handleBack)) {
      this._page!._handleBack()
    }
  }

  override _captureKey(e: KeyboardEvent): boolean | void {
    console.info('[PP] - ', e)
    this.startTimer()
    return false
  }

  private _lastTime = 0

  trackEvent(event: EventTypes, eventData: PlayerEventPayload) {
    let video_event: VideoEvent['video_event'] | null = null
    if (event === 'TimeUpdate') {
      this._lastTime = eventData.videoElement.currentTime ?? 0
      video_event = 'progress'
    } else if (event === 'Seeked') {
      if (eventData.videoElement.currentTime > this._lastTime) {
        video_event = 'fastforward'
      } else if (eventData.videoElement.currentTime <= this._lastTime) {
        video_event = 'rewind'
      }
    } else if (event === 'Pause') {
      video_event = 'pause'
    } else if (event === 'Play') {
      video_event = 'play'
    }

    if (video_event !== null && this._media && this.content) {
      const video_title = this.content.title
      const event: VideoEvent = {
        video_title,
        video_event,
        video_id: this._media.id,
        video_duration: eventData!.videoElement.duration,
        video_position: eventData!.videoElement.currentTime,
        video_progress:
          eventData!.videoElement.currentTime /
          eventData!.videoElement.duration,
      }
      this.fireAncestors('$trackMediaEvent', event)
    }
  }

  /**
   *  State Management
   *  Up dow and hiding is here.
   */

  override _construct() {
    this._enableHide = this._enableHide.bind(this)
    this.showUi = this.showUi.bind(this)
    this.onUiTimer = this.onUiTimer.bind(this)
    this.adEvent = this.adEvent.bind(this)
    this.watchControls = this.watchControls.bind(this)
  }
  private _controlsHidden = false
  private _lastState = 'Default'

  set controlsHidden(hidden: boolean) {
    if (hidden !== this._controlsHidden) {
      this._controlsHidden = hidden
      if (hidden && this.state !== 'ControlsHidden') {
        this._lastState = this.state ?? 'Default'
        this._setState('ControlsHidden')
      } else if (!hidden && this.state === 'ControlsHidden') {
        this._setState(this._lastState)
      }
    }
  }
  private _enableHide(enable: boolean) {
    debug.info('Enable Hide?', enable)
    this.hideable = enable
  }

  public get hideable() {
    return this._hideable
  }
  public set hideable(value: boolean) {
    if (this._hideable !== value) {
      this._hideable = value
      if (value) {
        this.startTimer()
      } else {
        this.clearTimer()
      }
    }
  }
  get controlsHidden() {
    return this._controlsHidden
  }
  watchControls(state: PlayerState) {
    if (state.controlsHidden !== this.controlsHidden) {
      this.controlsHidden = state.controlsHidden
    }
  }

  startTimer() {
    if (this.MediaPlayer.playing && this.hideable) {
      this.clearTimer(true)
      this._timeout = Registry.setTimeout(this.onUiTimer, 4000)
    }
  }

  clearTimer(isReset = false) {
    if (this._timeout !== null) {
      if (!isReset) console.info(' Clear Timer')
      Registry.clearTimeout(this._timeout)
      this._timeout = null
    }
  }

  private _uiTimerAvailable = true
  set upNextActive(available: boolean) {
    if (this._uiTimerAvailable !== available) {
      this._uiTimerAvailable = available
      if (available) {
        this.startTimer()
      } else {
        this._setState('Default.UpNext')
      }
    }
  }

  get upNextActive() {
    return this._uiTimerAvailable
  }

  _showMore() {
    debug.info('_showMore called?')
    this._setState('MoreState')
  }
  _hideMore() {
    this._setState('Default')
  }
  private _resumeUi() {
    defer(() => {
      this._setState('Default')
    })
  }
  pauseTimer() {
    this.clearTimer()
    this.showUi()
  }

  onUiTimer() {
    this._timeout = null
    this.hideUi()
  }
  hideUi() {
    if (this.upNextActive) {
      this._setState('HiddenState')
    }
  }
  showUi() {
    this._setState('Default')
  }

  get showAnimation() {
    if (!this._showMoreAnimation) {
      this._showMoreAnimation = this.animation({
        duration: 0.4,
        actions: [{ t: 'Wrapper', p: 'y', v: { 0: 0, 1: -400 } }],
      })
      this._showMoreAnimation.on('start', () => {
        debug.info('Show More animation started..')
      })
      this._showMoreAnimation.on('stop', () => {
        debug.info('Show More animation stopped..')
      })
      this._showMoreAnimation.on('finish', () => {
        debug.info('Show More animation finished..')
      })
    }
    return this._showMoreAnimation
  }

  get hideAnimation() {
    if (!this._hideAnimation) {
      this._hideAnimation = this.animation({
        duration: 0.4,
        actions: [
          { t: 'Wrapper', p: 'alpha', v: { 0: 1, 1: 0 } },
          { t: 'Wrapper', p: 'y', v: { 0: 0, 1: 500 } },
        ],
      })
    }
    return this._hideAnimation
  }

  _focusUpNext() {
    if (this._upNextActive) {
      this.currentFocus = this.UpNext
    } else {
      debug.warn('Up next not active.')
    }
  }

  static override _states(): (typeof AdePlayerPlane)[] {
    return [
      class Default extends this {
        override $enter() {
          this.startTimer()
        }
        static override _states() {
          return [
            class NoUpNext extends this {
              override $enter() {
                debug.info('Entering NO Up Next State')
                this.Progress.patch({
                  signals: {
                    down: this._downFromPlayerControls.bind(this),
                  },
                })
              }
            },
            class UpNext extends this {
              override $enter() {
                debug.info('Entering Up Next State')
                this.Progress.patch({
                  signals: {
                    down: this._downFromPlayerControls.bind(this),
                    right: this._rightFromPlayerControls.bind(this),
                  },
                })
              }
            },
          ]
        }
      },
      class HiddenState extends this {
        override $enter() {
          this._resumeUi = this._resumeUi.bind(this)
          if (this._pointerHelper !== null) {
            this._pointerHelper.on('moved', this._resumeUi)
          }
          this.hideAnimation?.start()
        }
        override $exit() {
          if (this._pointerHelper !== null) {
            this._pointerHelper.off('moved', this._resumeUi)
          }
          this.hideAnimation?.stop()
        }
        override _captureKey(e: KeyboardEvent) {
          super._captureKey(e)
          if (e.code === 'Enter') {
            debug.info('Got enter doing this thing...')
            this.MediaPlayer.playPause()
          }
          defer(() => {
            this._setState('Default')
          })
          return true
        }
      },
      class MoreState extends this {
        override _captureDown(): false | undefined {
          return false
        }
        override _captureUp(): false | undefined {
          return false
        }
        override $enter() {
          debug.info('Entering More State')
          this.clearTimer()
          this.showAnimation.start()
        }
        override $exit() {
          this.startTimer()
          this.showAnimation.stop()
        }
      },
      class ControlsHidden extends this {
        override _captureKey(e: KeyboardEvent) {
          if (e.code === 'Backspace') {
            return this._captureBack()
          }
          return false
        }
        override $enter() {
          this.clearTimer()
          this.hideAnimation?.start()
        }
        override $exit() {
          this.hideAnimation?.stop()
        }
      },
    ]
  }
}
