import { Lightning } from '@lightningjs/sdk'
import { MediaStillHereStates } from '../../components'
import { Debugger } from '../../lib'
const debug = new Debugger('dummyWatcher')

export function setupDummyListener(application: Lightning.Application) {
  debug.info('Setting up dummy listner')
  application.on('mediaState', state => {
    if (state === MediaStillHereStates.MEDIA_PLAYING) {
      debug.info('Media Playing: %s', state)
    } else {
      debug.info('Media Not Playing: %s', state)
    }
  })
}
