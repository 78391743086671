import { Lightning } from '@lightningjs/sdk'
import { MediaStillHereStates } from '../../components'
import type { AppCommonScreenSaverState, Webapis } from 'tizen-tv-webapis'

function getWebApis(): Webapis | null {
  try {
    if (typeof webapis !== 'undefined') {
      return webapis
    }
  } catch (error) {
    console.warn('unable to get webapis: %s', error.message)
  }
  return null
}
function tizenScreenSaver(state: AppCommonScreenSaverState) {
  try {
    const webapis = getWebApis()
    if (webapis !== null) {
      const { SCREEN_SAVER_ON, SCREEN_SAVER_OFF } =
        webapis.appcommon.AppCommonScreenSaverState
      webapis.appcommon.setScreenSaver(
        state,
        () =>
          console.info(
            'Samsung Screen Saver set to %s',
            state === SCREEN_SAVER_ON ? 'on' : 'off'
          ),
        error => {
          console.warn(JSON.stringify(error))
        }
      )
    }
  } catch (error) {
    console.warn('Error setting screensaver: %s', error.message, error)
  }
}
export function setupSamsungListeners(application: Lightning.Application) {
  try {
    const webapis = getWebApis()
    if (webapis !== null) {
      const { SCREEN_SAVER_ON, SCREEN_SAVER_OFF } =
        webapis.appcommon.AppCommonScreenSaverState
      application.on('mediaState', state => {
        if (state === MediaStillHereStates.MEDIA_PLAYING) {
          tizenScreenSaver(SCREEN_SAVER_OFF)
        } else {
          tizenScreenSaver(SCREEN_SAVER_ON)
        }
      })
    }
  } catch (e) {
    console.warn('Failed to setup samsung listeners')
  }
}
