import { Colors, Img, Lightning, Settings, Utils } from '@lightningjs/sdk'
import { Debugger, getCoordinateDimensions, pathJoin } from '../../lib'
import { HoverZone } from '../helpers/HoverZone'
import { CoordinateDimensions, isNumber } from '@adiffengine/engine-types'
import { PlayControlHoverButtons, PlayControlHoverName } from './lib/types'
const debug = new Debugger('PlayControlButton')

export interface PlayControlButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  action: keyof Lightning.Component.FireAncestorsMap | null
  actionPayload: any | null
  icon: string
  Icons: {
    Icon: object
    FocusIcon: object
  }
  buttonLabel: PlayControlHoverName
  HoverZone: typeof HoverZone
}

export interface PlayControlButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    hovered(type: PlayControlHoverName): void
    selected(): boolean | void
    pressed(coords?: CoordinateDimensions): boolean | void
    released(coords?: CoordinateDimensions): boolean | void
  }
}

export class PlayControlButton
  extends Lightning.Component<
    PlayControlButtonTemplateSpec,
    PlayControlButtonTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<PlayControlButtonTemplateSpec>
{
  public static width: number = 50
  public static height: number = 50
  public action: keyof Lightning.Component.FireAncestorsMap | null = null
  public actionPayload: any | null = null
  private _pressAnimation: Lightning.types.Animation | null = null
  static override _template(): Lightning.Component.Template<PlayControlButtonTemplateSpec> {
    return {
      w: this.width,
      h: this.height,
      x: 0,
      y: 0,
      Icons: {
        mount: 0.5,
        x: 30,
        y: 30,
      },
      HoverZone: {
        mount: 0.5,
        type: HoverZone,
        x: this.width / 2,
        y: this.height / 2,
        w: 80,
        h: 80,
        signals: {
          hovered: '_hovered',
          pressed: '_pressed',
          released: '_released',
        },
      },
    }
  }
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  override _init() {
    const theme = this.fireAncestors('$theme')
    const width = this.finalW
    const height = this.finalH

    this.tag('Icons')!.patch({
      x: width / 2,
      y: height / 2,
      mount: 0.5,
      h: height * 0.8,
      w: width * 0.8,
      Icon: {
        x: 0,
        y: 0,
        alpha: 1,
        h: height * 0.8,
        w: width * 0.8,
        texture: Img(`images/icons/${this._icon}`).contain(
          height * 0.8,
          width * 0.8
        ),
        color: Colors(theme.palette.text).get(),
      },
      FocusIcon: {
        x: 0,
        y: 0,
        alpha: 0,
        h: height * 0.8,
        w: width * 0.8,
        texture: Img(pathJoin(['images/icons', this._icon as string])).contain(
          height * 0.8,
          width * 0.8
        ),
        color: Colors(theme.palette.highlights[500]).get(),
      },
    })
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { t: 'Icons.Icon', p: 'alpha', v: { 0: 1, 1: 0 } },
        { t: 'Icons.FocusIcon', p: 'alpha', v: { 0: 0, 1: 1 } },
        { t: 'Icons', p: 'scale', v: { 0: 1, 1: 1.2 } },
      ],
    })
    this._pressAnimation = this.animation({
      duration: 0.2,
      actions: [
        { t: 'Icons', p: 'scale', v: { 0: 1.2, 1: 0.8 } },
        { t: 'Icons', p: 'alpha', v: { 0: 1, 1: 0.6 } },
      ],
    })
  }
  public buttonLabel = PlayControlHoverButtons.unknown
  private _icon: string | null = null
  set icon(icon: string) {
    this._icon = icon
  }
  private _isDown = false
  override _captureEnterRelease() {
    this._isDown = false
    this._pressAnimation?.stop()
    this._focusAnimation?.start()
    if (this.action) {
      this.fireAncestors(this.action, this.actionPayload)
    } else {
      this.signal('released')
      return this.signal('selected')
    }
  }

  override _captureEnter(): boolean | void {
    debug.info('Play Control Button Enter')
    this._isDown = true
    this._focusAnimation?.finish()
    this._pressAnimation?.start()
    this.signal('pressed')
  }
  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._pressAnimation?.finish()
    this._focusAnimation?.stop()
  }

  _pressed() {
    debug.info('Play control hover button pressed', this)
    this.signal('pressed', getCoordinateDimensions(this))
    this._captureEnter()
  }
  _released() {
    debug.info('Play control hover button released', this)
    this.signal('released', getCoordinateDimensions(this))
    this._captureEnterRelease()
  }
  _hovered() {
    debug.info('hovered play control', this.buttonLabel)
    this.signal('hovered', this.buttonLabel)
  }
}
