import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  CoordinateDimensions,
  HorizontalList,
} from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isEqual from 'fast-deep-equal/es6'
import {
  Debugger,
  cp,
  getClosestIndexByX,
  getCoordinateDimensions,
  isGoodArray,
} from '../lib'
import {
  SimpleHorizontalCardTemplateConfig,
  SimpleHorizontalContentCard,
  SimpleHorizontalContentCardTemplateSpec,
} from './SimpleHorizontalContentCard'
const debug = new Debugger('SimpleHorizontalCardHeaderList')
export interface SimpleHorizontalCardHeaderListTemplateSpec
  extends Lightning.Component.TemplateSpec,
    Pick<
      SimpleHorizontalContentCardTemplateSpec,
      'contentConverter' | 'cardConfig'
    > {
  Header: object
  List: typeof List
  title: string
  items: ContentItem[]
  spacing: number
  width: number
}

export interface SimpleHorizontalCardHeaderListTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class SimpleHorizontalCardHeaderList
  extends Lightning.Component<
    SimpleHorizontalCardHeaderListTemplateSpec,
    SimpleHorizontalCardHeaderListTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<SimpleHorizontalCardHeaderListTemplateSpec>,
    HorizontalList
{
  Header = this.getByRef('Header')!
  List = this.getByRef('List')!
  static height = SimpleHorizontalContentCard.defaultConfig.height + 100
  static spacing = 20
  static width = 1920 - 160
  static override _template(): Lightning.Component.Template<SimpleHorizontalCardHeaderListTemplateSpec> {
    return {
      h: 1080,
      w: this.width,
      Header: {
        x: 0,
        y: 0,
        w: cp,
        h: 100,
        color: Colors('text').get(),
        text: {
          text: 'More Content',
          fontFace: 'ExtraBold',
          fontSize: 36,
          wordWrapWidth: this.width,
          maxLines: 1,
        },
      },
      List: {
        x: 0,
        y: 100,
        spacing: 40,
        type: List,
        itemType: SimpleHorizontalContentCard,
        w: this.width,
        h: SimpleHorizontalContentCard.defaultConfig.height,
        direction: 'row',
        signals: {
          reposition: 'reposition',
        },
      },
    }
  }

  override _construct() {
    this.reposition = this.reposition.bind(this)
    this._signals = {
      reposition: this.reposition,
    }
  }

  set width(w: number) {
    this.patch({ w, List: { w } })
  }

  reposition() {
    this.List.reposition()
  }
  private _signals = {
    reposition: this.reposition,
  }
  get cardSignals() {
    return this._signals
  }

  private _cardConfig: Partial<SimpleHorizontalCardTemplateConfig> = {}
  set cardConfig(config: Partial<SimpleHorizontalCardTemplateConfig>) {
    if (!isEqual(this._cardConfig, config)) {
      this._cardConfig = config
      this.cardItems = this.getCardItems()
    }
  }
  get cardConfig() {
    return this._cardConfig
  }
  set title(text: string) {
    this.Header.patch({ text: { text } })
  }
  get title() {
    return this.Header.text?.text ?? ''
  }

  private _items: ContentItem[] = []
  set items(items: ContentItem[]) {
    if (!isEqual(items, this.items)) {
      this._items = items
      this.cardItems = this.getCardItems()
      debug.info('Patched List with items', this.items, this.List)
    }
  }
  get items(): ContentItem[] {
    return this._items
  }
  private _cardItems: Lightning.Element.PatchTemplate<SimpleHorizontalContentCardTemplateSpec>[] =
    []
  set cardItems(
    items: Lightning.Element.PatchTemplate<SimpleHorizontalContentCardTemplateSpec>[]
  ) {
    if (!isEqual(items, this._cardItems)) {
      this._cardItems = items
      if (isGoodArray(this._cardItems)) {
        this.List.patch({ items: this.cardItems })
      } else {
        this.List.clear()
      }
    }
  }
  get cardItems() {
    return this._cardItems
  }

  get listItems() {
    return this.List.items
  }

  set spacing(spacing: number) {
    this.List.patch({ spacing })
  }
  get spacing() {
    return this.List.spacing
  }

  private _contentConverter:
    | SimpleHorizontalContentCardTemplateSpec['contentConverter']
    | undefined = undefined
  set contentConverter(
    converter: SimpleHorizontalContentCardTemplateSpec['contentConverter']
  ) {
    if (this._contentConverter !== converter) {
      this._contentConverter = converter
      this.cardItems = this.getCardItems()
    }
  }
  get contentConverter() {
    return this._contentConverter!
  }

  getCardItems(): Lightning.Element.PatchTemplate<SimpleHorizontalContentCardTemplateSpec>[] {
    return this.items.map(i => {
      const out: Lightning.Element.PatchTemplate<SimpleHorizontalContentCardTemplateSpec> =
        {
          ...SimpleHorizontalContentCard.getCardDimensions(this.cardConfig),
          content: i,
          cardConfig: this.cardConfig,
          contentConverter: this.contentConverter,
          signals: this.cardSignals,
        }
      return out
    })
  }

  override _handleRight(): boolean | void {
    if (this.List.index === this.List.items.length - 1) {
      return this.signal('right', this.focusedCoords)
    } else {
      return false
    }
  }
  override _handleLeft(): boolean | void {
    if (this.List.index === 0) {
      return this.signal('left', this.focusedCoords)
    } else {
      return false
    }
  }
  override _handleUp(): boolean | void {
    return this.signal('up', this.focusedCoords)
  }
  override _handleDown(): boolean | void {
    return this.signal('down', this.focusedCoords)
  }
  override _handleEnter(): boolean | void {
    const current = this.items[this.List.index]
    if (current) {
      this.signal('contentSelected', current)
    } else {
      return false
    }
  }

  get focusedCoords() {
    const focused = this.List.items[this.List.index]
    let coords: CoordinateDimensions | null = null
    if (focused) {
      coords = getCoordinateDimensions(focused, false)
    }
    return coords
  }

  getFocusedCoords() {
    return this.focusedCoords
  }

  setClosestByX(coords?: CoordinateDimensions | null) {
    if (coords != null) {
      const closest = getClosestIndexByX(coords, this.List.items)
      this.List.setIndex(closest)
    }
  }

  override _getFocused() {
    return this.List
  }
  override _init() {
    this.List.patch({
      w: this.w,
    })
  }
}
