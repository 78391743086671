import { Colors, Lightning, Registry, Router, Settings } from '@lightningjs/sdk'
import {
  UserActivityState,
  UserKeyMonitor,
} from '../components/libs/UserKeyMonitor'
import { getStoredTheme } from '../themes'

import { AdeCloseReason } from '@adiffengine/engine-types'
import { AdeButtonCollection } from '../components'
import { AdeModals } from '../components/types'
import { Debugger } from '../lib'
const debug = new Debugger('AreYouStillThere')
debug.enabled = false

export interface AreYouStillThereTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Content: typeof AreYouStillThereContent
}
export const ARE_YOU_STILL_THERE_WIDTH = 1200
export const ARE_YOU_STILL_THERE_HEIGHT = 600

export interface AreYouStillThereResultGone {
  here: false
  reason: 'button' | 'timeout'
}
export interface AreYouStillThereResultHere {
  here: true
}
export type AreYouStillThereResult =
  | AreYouStillThereResultGone
  | AreYouStillThereResultHere

export class AreYouStillThere
  extends Lightning.Component<AreYouStillThereTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AreYouStillThereTemplateSpec>
{
  Content = this.getByRef('Content')!
  static defaultLabel = 'Are you still there?'
  static defaultText = `Just wondering if you're still around.`
  static width = ARE_YOU_STILL_THERE_WIDTH
  static height = ARE_YOU_STILL_THERE_HEIGHT
  static override _template(): Lightning.Component.Template<AreYouStillThereTemplateSpec> {
    return {
      alpha: 0.0001,
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      zIndex: 0,
      Background: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        color: Colors('background').alpha(0.6).get(),
        rect: true,
      },
      Content: {
        type: AreYouStillThereContent,
      },
    }
  }
  override _construct() {
    this._trackActiveModal = this._trackActiveModal.bind(this)
  }

  _showAnimation: Lightning.types.Animation | null = null
  get showAnimation(): Lightning.types.Animation {
    if (this._showAnimation === null) {
      this._showAnimation = this.animation({
        duration: 0.3,
        actions: [
          {
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
        ],
      })
      this._showAnimation.on('start', () => {
        this.Content.Buttons.setIndex(0)
        this.patch({
          zIndex: 100,
        })
      })
      this._showAnimation.on('stopFinish', () => {
        this.patch({
          zIndex: 0,
        })
      })
    }
    return this._showAnimation
  }

  override _focus() {
    this.showAnimation.start()
  }

  override _unfocus() {
    this.showAnimation.stop()
  }

  override _getFocused() {
    return this.Content
  }
  _activated = false
  override _active() {
    debug.info('Active')
    this._activated = true
    this._setState('Monitoring')
  }
  override _inactive() {
    debug.info('In Active')
    this._activated = false
    this._setState('NotMonitoring')
  }

  _activeModal: AdeModals | null = null
  set activeModal(modal: AdeModals | null) {
    if (modal !== this._activeModal) {
      this._activeModal = modal
      if (this._activeModal && this._activated) {
        this._setState('NotMonitoring')
      } else if (!this._activeModal && this._activated) {
        this._setState('Monitoring')
      }
    }
  }
  _trackActiveModal(modal: AdeModals, active: boolean) {
    if (active) this.activeModal = modal
    else this.activeModal = null
  }

  static override _states() {
    return [
      class NotMonitoring extends this {
        override $enter() {
          this.application.on('modal', this._trackActiveModal)
        }
      },
      class Monitoring extends this {
        _keyMonitor = new UserKeyMonitor()
        _onKeyStateChange(state: UserActivityState) {
          if (state === 'inactive') {
            this._setState('Checking')
          }
        }
        override $enter() {
          this._keyMonitor = new UserKeyMonitor()
          this._keyMonitor.start()
          this._keyMonitor.on('stateChange', this._onKeyStateChange.bind(this))
          this.application.on('modal', this._trackActiveModal)
        }

        override _captureKey() {
          this._keyMonitor.ping()
          return false
        }
        override $exit() {
          this.application.off('modal', this._trackActiveModal)
          this._keyMonitor.destroy()
        }
      },
      class Checking extends this {
        private _timeout: ReturnType<typeof Registry.setTimeout> | null = null
        override $enter() {
          Router.focusWidget('AreYouStillThere')
          this._resetTimeout()
          this.patch({
            zIndex: 100,
          })
          this.Content.patch({
            signals: {
              stay: '_stay',
              exit: '_exit',
            },
          })
        }
        override $exit() {
          this._clearTimeout()
          this.patch({
            zIndex: 0,
          })
          this.Content.patch({
            signals: {},
          })
        }
        _stay() {
          Router.focusPage()
          this._setState('Monitoring')
        }
        _exit() {
          this._exitApp()
        }
        override _captureKey() {
          this._resetTimeout()
          return false
        }
        override _captureBack() {
          Router.focusPage()
          this._setState('Monitoring')
        }
        private _resetTimeout() {
          this._clearTimeout()
          const timeoutDuration = Settings.get(
            'app',
            'ARE_YOU_STILL_THERE_TIMEOUT_IN_SECONDS',
            20
          )
          debug.info(
            'Reset are you still there timeout, will exit in %s seconds',
            timeoutDuration
          )
          this._timeout = Registry.setTimeout(
            this._exitApp.bind(this),
            timeoutDuration * 1000
          )
        }
        private _clearTimeout() {
          if (this._timeout !== null) {
            Registry.clearTimeout(this._timeout)
            this._timeout = null
          }
        }
        private _exitApp() {
          this.fireAncestors('$exitApp', AdeCloseReason.DONE, true)
        }
      },
    ]
  }
}
type MenuButtonPayload = 'exit' | 'stay'
interface AreYouStillThereContentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  TextWrapper: {
    Label: object
    Text: object
    Buttons: typeof AdeButtonCollection<MenuButtonPayload>
  }

  // AutomaticCancelProgress: object
}
interface AreYouStillThereContentTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    stay(): void
    exit(): void
  }
}
class AreYouStillThereContent extends Lightning.Component<
  AreYouStillThereContentTemplateSpec,
  AreYouStillThereContentTypeConfig
> {
  TextWrapper = this.getByRef('TextWrapper')!
  Label = this.TextWrapper.getByRef('Label')!
  Text = this.TextWrapper.getByRef('Text')!
  Buttons = this.TextWrapper.getByRef('Buttons')!
  static override _template(): Lightning.Component.Template<AreYouStillThereContentTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { MainMenuConfig },
    } = theme
    return {
      x: (1920 - ARE_YOU_STILL_THERE_WIDTH) / 2,
      y: (1080 - ARE_YOU_STILL_THERE_HEIGHT) / 2,
      w: ARE_YOU_STILL_THERE_WIDTH,
      h: ARE_YOU_STILL_THERE_HEIGHT,
      Background: {
        rect: true,
        rtt: true,
        x: 0,
        y: 0,
        w: ARE_YOU_STILL_THERE_WIDTH,
        h: ARE_YOU_STILL_THERE_HEIGHT,
        shader: {
          strokeColor: Colors('backgroundGradient').get(),
          stroke: 8,
          type: Lightning.shaders.RoundedRectangle,
          radius: MainMenuConfig.radius,
        },
        color: Colors('background').get(),
      },
      TextWrapper: {
        x: 120,
        y: 80,
        h: ARE_YOU_STILL_THERE_HEIGHT - 200,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        },
        Label: {
          text: {
            fontFace: 'Bold',
            text: 'Are you still there?',
            maxLines: 2,
            wordWrap: true,
            wordWrapWidth: ARE_YOU_STILL_THERE_WIDTH - 100,
            fontSize: 64,
          },
        },
        Text: {
          flexItem: {
            marginTop: 24,
          },
          text: {
            fontFace: 'Text',
            text: `Just wondering if you're still around.`,
            fontSize: 46,
            maxLines: 3,
            wordWrap: true,
            wordWrapWidth: ARE_YOU_STILL_THERE_WIDTH - 100,
          },
        },
        Buttons: {
          type: AdeButtonCollection,
          direction: 'row',
          flexItem: {
            marginTop: 24,
          },
          buttonSize: {
            w: 240,
            h: 80,
          },
          signals: {
            selected: '_selected',
          },
          buttons: [
            {
              buttonText: 'Still Here',
              payload: 'stay',
            },
            {
              buttonText: 'Done',
              payload: 'exit',
            },
          ],
        },
      },
    }
  }

  reset() {
    this.Buttons.setIndex(0)
  }

  override _getFocused() {
    return this.Buttons
  }

  _selected(payload: MenuButtonPayload) {
    this.signal(payload)
  }
  set title(text: string) {
    this.Label.patch({
      text: {
        text,
      },
    })
  }
  set description(text: string) {
    this.Text.patch({
      text: {
        text,
      },
    })
  }

  override _init() {
    this.title = Settings.get(
      'app',
      'SCREENSAVER_TITLE',
      'Are you still there?'
    )
    this.description = Settings.get(
      'app',
      'SCREENSAVER_TEXT',
      "Just checking if you're still around"
    )
  }
}
