import { Lightning } from '@lightningjs/sdk'
import { VideoCardGrid } from '../components'
import isEqual from 'fast-deep-equal/es6'
import { ContentItem, ItemPaths } from '@adiffengine/engine-types'
export interface PlainTileScreentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Title: object
    Subtitle: object
  }
  Grid: typeof VideoCardGrid
  title: string
  subtitle: string
  content: ContentItem[]
  action: ItemPaths
}
export interface PlainTileScreentTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class PlainTileScreen
  extends Lightning.Component<
    PlainTileScreentTemplateSpec,
    PlainTileScreentTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<PlainTileScreentTemplateSpec>
{
  Content = this.getByRef('Content')!
  Title = this.Content.getByRef('Title')!
  Subtitle = this.Content.getByRef('Subtitle')!
  Grid = this.getByRef('Grid')!
  static override _template(): Lightning.Component.Template<PlainTileScreentTemplateSpec> {
    return {
      Content: {
        x: 80,
        y: 80,
        w: 1920 - 160,
        h: 1080 - 160,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        },
        Title: {
          text: {
            text: 'Title',
            fontSize: 36,
            fontFace: 'Bold',
          },
        },
        Subtitle: {
          text: {
            text: 'Subtitle',
            fontSize: 24,
            fontFace: 'Regular',
          },
        },
      },
      Grid: {
        x: 80,
        y: 200,
        h: 1080 - 160 - 200,
        w: 1920 - 160,
        type: VideoCardGrid,
        columns: 5,
      },
    }
  }
  set action(action: ItemPaths) {
    this.Grid.action = action
  }
  get action() {
    return this.Grid.action
  }
  set title(text: string) {
    if (this.title !== text) {
      this.Title.patch({
        text: { text },
      })
    }
  }
  get title(): string {
    return this.Title.text?.text ?? ''
  }
  set subtitle(text: string) {
    if (this.subtitle !== text) {
      this.Subtitle.patch({
        text: { text },
      })
    }
  }
  get subtitle(): string {
    return this.Subtitle.text?.text ?? ''
  }
  private _content: ContentItem[] = []

  set content(x: ContentItem[]) {
    if (!isEqual(x, this._content)) {
      this._content = x
      this.Grid.patch({ items: x.map(x => ({ content: x })) })
    }
  }

  override _getFocused() {
    return this.Grid
  }
}
