import { Lightning, Settings } from '@lightningjs/sdk'
import { setupSamsungListeners } from './samsung'
import { setupDummyListener } from './dummyWatcher'

export type DeviceListener = (app: Lightning.Application) => void
export const watchers: Record<string, DeviceListener> = {
  samsung: setupSamsungListeners,
}
export function getDeviceListener(): DeviceListener | null {
  const environment = Settings.get('app', 'environment', 'production')
  const device = Settings.get('app', 'platform', 'smart-tv')
  const watcher = watchers[device]
    ? watchers[device]
    : environment !== 'production'
      ? setupDummyListener
      : null
  return watcher
}
