import { Colors, Img, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
const debug = new Debugger('SimpleFrame')
export interface SimpleFrameTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Frame: object
    FrameFade: object
    Art: object
    Inset: object
  }
  image: string
  inset: number
  frameColor: number | string
}
export const FRAME_RESIZE_DURATION = 0.4
export const FRAME_COLOR_DURATION = 0.4
export const FRAME_INSET_SIZE = 40
export const FRAME_INSET_RATIO = 0.2
export const FRAME_BEVEL_SIZE = FRAME_INSET_SIZE * FRAME_INSET_RATIO
export const FRAME_IMAGE_WIDTH =
  1920 - (FRAME_INSET_SIZE + FRAME_BEVEL_SIZE) * 2
export const FRAME_IMAGE_HEIGHT =
  1080 - (FRAME_INSET_SIZE + FRAME_BEVEL_SIZE) * 2

export interface SimpleFrameTypeConfig extends Lightning.Component.TypeConfig {}
export class SimpleFrame
  extends Lightning.Component<SimpleFrameTemplateSpec, SimpleFrameTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<SimpleFrameTemplateSpec>
{
  Content = this.getByRef('Content')!
  Frame = this.Content.getByRef('Frame')!
  FrameFade = this.Content.getByRef('FrameFade')!
  Art = this.Content.getByRef('Art')!
  static override _template(): Lightning.Component.Template<SimpleFrameTemplateSpec> {
    return {
      Content: {
        x: 0,
        y: 0,
        h: 1080,
        w: 1920,
        color: Colors('#F7F7F2').get(),
        rect: true,
        rtt: true,
        Frame: {
          x: 1920 / 2,
          y: 1080 / 2,
          mountX: 0.5,
          mountY: 0.5,
          w: 1920 - FRAME_INSET_SIZE * 2,
          h: 1080 - FRAME_INSET_SIZE * 2,
          rect: true,
          color: Colors('#F7F7F2').darker(0.1).get(),
        },
        FrameFade: {
          x: 1920 / 2,
          y: 1080 / 2,
          mountX: 0.5,
          mountY: 0.5,
          w: 1920 - FRAME_INSET_SIZE * 2,
          h: 1080 - FRAME_INSET_SIZE * 2,
          rect: true,
          color: Colors('#4C4D2E').alpha(0.3).get(),
          shader: {
            type: Lightning.shaders.FadeOut,
            fade: 40 * 0.75,
          },
        },
        Art: {
          x: 1920 / 2,
          y: 1080 / 2,
          mountX: 0.5,
          mountY: 0.5,
          w: FRAME_IMAGE_WIDTH,
          h: FRAME_IMAGE_HEIGHT,
          rect: true,
        },
        Inset: {
          x: 1920 / 2,
          y: 1080 / 2,
          mountX: 0.5,
          mountY: 0.5,
          w: 1920 - (FRAME_INSET_SIZE + FRAME_BEVEL_SIZE) * 2,
          h: 1080 - (FRAME_INSET_SIZE + FRAME_BEVEL_SIZE) * 2,
          rect: true,
          rtt: true,
          color: 0x00000000,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 1,
            strokeColor: Colors('black').alpha(0.6).get(),
          },
        },
      },
    }
  }
  _frameColor: number | string = Colors('#F7F7F2').get()
  set frameColor(color: number | string) {
    if (color !== this._frameColor) {
      this.Content.patch({
        smooth: {
          color: [Colors(color).get(), { duration: FRAME_COLOR_DURATION }],
        },
        Frame: {
          smooth: {
            color: [
              Colors(color).darker(0.1).get(),
              { duration: FRAME_COLOR_DURATION },
            ],
          },
        },
      })
    }
  }
  _image: string | undefined = undefined
  set image(image: string) {
    if (image !== this._image) {
      this._image = image
      debug.info('Image Set ', this.__active)
      if (this.__active) this._setState('ReplaceImage')
    }
  }
  get image() {
    return this._image!
  }

  _inset = FRAME_BEVEL_SIZE
  set inset(inset: number) {
    debug.info('Setting inset current: %s, update: %s', this.inset, inset)
    if (this.inset !== inset) {
      this._inset = inset
      this.resizeInset()
    }
  }
  get inset() {
    return this._inset
  }

  resizeInset() {
    debug.info('Resizing Inset', this.inset)
    const bevelSize = this.inset * FRAME_INSET_RATIO
    this.Content.patch({
      Frame: {
        smooth: {
          w: [1920 - this.inset * 2, { duration: FRAME_RESIZE_DURATION }],
          h: [1080 - this.inset * 2, { duration: FRAME_RESIZE_DURATION }],
        },
      },
      FrameFade: {
        smooth: {
          w: [1920 - this.inset * 2, { duration: FRAME_RESIZE_DURATION }],
          h: [1080 - this.inset * 2, { duration: FRAME_RESIZE_DURATION }],
        },
      },
      Art: {
        smooth: {
          w: [
            1920 - (this.inset + bevelSize) * 2,
            { duration: FRAME_RESIZE_DURATION },
          ],
          h: [
            1080 - (this.inset + bevelSize) * 2,
            { duration: FRAME_RESIZE_DURATION },
          ],
        },
      },
      Inset: {
        smooth: {
          w: [
            1920 - (this.inset + bevelSize) * 2,
            { duration: FRAME_RESIZE_DURATION },
          ],
          h: [
            1080 - (this.inset + bevelSize) * 2,
            { duration: FRAME_RESIZE_DURATION },
          ],
        },
      },
    })
  }

  __active = false
  override _active() {
    this.__active = true
    debug.info('Active ', this.__active)
    if (this.image) this._setState('ReplaceImage')
  }
  override _inactive() {
    this.__active = false
  }
  _hideAnimation: Lightning.types.Animation | null = null
  get hideAnimation() {
    if (this._hideAnimation === null) {
      this._hideAnimation = this.Content.animation({
        duration: 0.2,
        actions: [{ t: 'Art', p: 'alpha', v: { 0: 1, 1: 0.01 } }],
      })
    }
    return this._hideAnimation
  }
  static override _states(): (typeof SimpleFrame)[] {
    return [
      class Default extends this {},
      class ReplaceImage extends this {
        private _imagePatched = false
        imageLoaded() {
          debug.info('TX loaded')
          if (this._imagePatched) {
            this._imagePatched = false
            this.exit()
            this._imagePatched = false
          }
        }
        imageError() {
          debug.info('TX Error', this)
        }
        imageHidden() {
          debug.info('Hidden Patching', this.image)
          this._imagePatched = true
          this.Art.patch({
            texture: Img(this.image).cover(
              FRAME_IMAGE_WIDTH,
              FRAME_IMAGE_HEIGHT
            ),
            rtt: true,
          })
          this.Art.on('txLoaded', this.imageLoaded.bind(this))
        }
        exit() {
          debug.info('Exiting', this.Art)
          this.hideAnimation.stop()
          this._setState('Default')
        }

        override $enter() {
          this.Art.on('txLoaded', this.imageLoaded.bind(this))
          this.Art.on('txUnloaded', () => {
            debug.info('Texture Unloaded')
          })
          this.Art.on('txError', this.imageError.bind(this))
          this.hideAnimation.on('finish', this.imageHidden.bind(this))
          this.hideAnimation.start()
        }
        override $exit() {
          debug.info('Exiting', this.Art)
          this.Art.off('txLoaded', this.imageLoaded)
          this.hideAnimation.off('finish', this.imageHidden)
        }
      },
    ]
  }
}
