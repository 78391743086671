export default {
  appSettings: {
    debug: false,
    api_url: 'https://cms-dev.adetv.co/api/valhalla-api',
    search_url: 'https://search-dev.adetv.co',
    keys: {
      '8': 'Back',
      '13': 'Enter',
      '27': 'Exit',
      '33': 'PageUp',
      '34': 'PageDown',
      '37': 'Left',
      '38': 'Up',
      '39': 'Right',
      '40': 'Down',
      '68': 'Debug',
      '71': 'Guide',
      '72': 'Hint',
      '73': 'Info',
      '76': 'Last',
      '77': 'Menu',
      '78': 'Next',
      '80': 'Prev',
      '83': 'Star',
      '84': 'TextBanner',
      '86': 'VoiceGuidance',
      '179': 'PlayPause',
      '219': 'Rewind',
      '221': 'FastForward',
      '227': 'Rewind',
      '228': 'FastForward',
      '403': 'ColorA',
      '404': 'ColorB',
      '405': 'ColorC',
      '406': 'ColorD',
    },
    APP_MUX_ENV_KEY: 'abi38lce0k2m5r5tledc8c6f5',
    APP_MUX_DEBUG: true,
    APP_SHORT_NAME: 'thor',
    APP_ERROR_CODE: 'THOR',
    ADS_ENABLED: false,
    UP_NEXT_TIME_IN_SECONDS: 20,
    UP_NEXT_OFFSET_FROM_END: 3,
    UP_NEXT_DISABLED: false,
    DEBUG_UP_NEXT: false,
    SENTRY_DSN:
      'https://83e766933bd84edab34eac29052f1155@o210186.ingest.sentry.io/4504946330566656',
    SENTRY_PROJECT: 'thor',
    FIREBASE_APP_ID: '1:311719570977:web:c449fb696c86f2ea50bd60',
    FIREBASE_MEASUREMENT_ID: 'G-C3PXCZDEF6',
    PLAUSIBLE_DOMAIN: 'thor.adeengine.com',
    FORCE_GA_TAG: true,
    enablePointer: false,
    SCREENSAVER_ENABLED: true,
    SCREENSAVER_TIMEOUT_IN_MINUTES: 30,
    MAX_VIDEOS_BEFORE_CHECK: 5,
    ARE_YOU_STILL_THERE_TIMEOUT_IN_SECONDS: 20,
    stage: {
      clearColor: '0x00000000',
    },
    version: '24.5.26.0-alpha.0',
    buildDate: 'Thu May 30 2024 09:44:00 GMT-0700 (Pacific Daylight Time)',
    appMetadata: {
      name: 'Mitu',
      identifier: 'co.weade.mitu',
      description: 'Mitu Engine App',
      author: 'A Different Engine <hi@adifferentengine.com>',
      icon: './images/icon.png',
      lg: {
        id: 'co.adeengine.mitu',
        version: '2024.5.57',
        vendor: 'A Different Engine',
        type: 'web',
        main: 'index.html',
        title: 'Mitú TV',
        bgColor: '#1c2029',
        resolution: '1920x1080',
        disableBackHistoryAPI: true,
        icon: 'icon.png',
        largeIcon: 'largeIcon.png',
      },
      version: '24.5.26.0-alpha.0',
      hash: 'f32d4f0',
      branch: 'develop',
      commitDate: 'Last Commit: May 29th, 2024 at 12:43',
      buildDate: 'Thu May 30 2024 09:44:00 GMT-0700 (Pacific Daylight Time)',
    },
    environment: 'production',
    platform: 'smart-tv',
  },
  platformSettings: {
    path: './',
    log: false,
    clearColor: '0x00000000',
    showVersion: false,
    proxyUrl:
      'https://us-central1-valhalla-f2ec8.cloudfunctions.net/feedFM-imageProxy',
    router: {
      backtracking: true,
    },
    inspector: true,
  },
  appData: {},
}
