import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  CoordinateDimensions,
  HorizontalList,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import {
  Debugger,
  cp,
  getClosestIndexByX,
  getCoordinateDimensions,
} from '../lib'
import { SongCard } from './SongCard'
const debug = new Debugger('SongList')

export class SongList
  extends Lightning.Component<SongListTemplateSpec, SongListTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<SongListTemplateSpec>,
    HorizontalList
{
  Header = this.getByRef('Header')!
  List = this.getByRef('List')!
  static height = SongCard.height + 100

  static override _template(): Lightning.Component.Template<SongListTemplateSpec> {
    return {
      w: 1920 - 160,
      h: 300,
      Header: {
        x: 0,
        y: 0,
        text: {
          text: '',
          fontSize: 36,
          fontFace: 'Bold',
          wordWrap: false,
        },
      },
      List: {
        type: List,
        itemType: SongCard,
        direction: 'row',
        spacing: 40,
        w: cp,
        h: SongCard.height,
        x: 0,
        y: 60,
      },
    }
  }
  set title(text: string) {
    this.Header.patch({
      text: {
        text: text.toUpperCase(),
      },
    })
  }
  private _items: ContentItem[] = []
  set songs(songs: ContentItem[]) {
    this._items = songs
    if (songs.length > 0) {
      this.List.patch({
        w: this.w,
        items: songs.map(item => {
          return {
            content: item,
          }
        }),
      })
    }
    this.List.scroll = {
      forward: this.w - 20,
      backward: 0,
    }
  }
  get currentFocus() {
    return this.List.items[this.List.index]
  }
  override _captureLeft(): boolean | void {
    if (this.List.index === 0) this.signal('left', this.focusedCoords)
    else return false
  }
  override _captureRight(): boolean | void {
    if (this.List.index === this.List.items.length - 1)
      return this.signal('right', this.focusedCoords)
    else return false
  }
  override _captureUp(): boolean | void {
    return this.signal('up', this.focusedCoords)
  }
  override _captureDown(): boolean | void {
    return this.signal('down', this.focusedCoords)
  }

  override _captureEnter(): boolean | void {
    const item = this._items[this.List.index]
    const playlist = item
      ? this._items.filter(i => i.id !== item.id)
      : undefined
    if (item) this.signal('contentSelected', item, playlist)
  }

  getFocusedCoords() {
    return this.focusedCoords
  }
  get focusedCoords() {
    const focused = this.List.items[this.List.index]
    let coords: CoordinateDimensions | null = null
    if (focused) {
      coords = getCoordinateDimensions(focused, false)
    }
    debug.info('Getting focused coords for', focused, coords)
    return coords
  }

  setClosestByX(coords?: CoordinateDimensions | null) {
    if (coords != null) {
      const closest = getClosestIndexByX(coords, this.List.items)
      this.List.setIndex(closest)
    }
  }

  override _getFocused() {
    return this.List
  }
}

export interface SongListTemplateSpec extends Lightning.Component.TemplateSpec {
  Header: object
  List: typeof List
  title: string
  songs: ContentItem[]
}

export interface SongListTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}
