import { isString, isNumber } from './utils'

export interface ImageSet {
  getForWidth: (targetWidth: number) => string | null
  getUrl: (size: string) => string | null
  getBiggest: () => string | null
  toJSON: () => unknown
}
export type ItemPaths = 'details' | 'player' | 'category' | 'preview'
export type ID = string | number
export interface ContentSource<T = Record<string, unknown>> {
  src: string
  type: 'hls' | 'dash' | 'mp4' | 'mp3' | 'aac'
  bitrate?: number
  platformDetails?: T
}

export type MediaTypes = 'tv' | 'movie' | 'all'
export type Maybe<T> = NonNullable<T> | undefined | null

export interface MediaImages {
  box?: ImageSet
  wide?: ImageSet
  wallpaper?: ImageSet
  thumb?: ImageSet
}

export interface MediaDetailsBase {
  sources: ContentSource[]
  id: ID
  extra_type?: 'trailer' | 'extra' | 'recap' | 'preview'
  title: string
  subtitle?: string
  description?: string
  button_text?: string
  release?: Date
  duration?: number
  paths?: {
    player: string
  }
  images?: MediaImages
  ad_data?: Record<string, unknown>
}
export interface MediaDetailsCore extends MediaDetailsBase {
  type: 'episode' | 'movie' | 'extra'
  seasonNumber?: ID
  episodeNumber?: ID
  ad_data?: Record<string, unknown>
}

export interface MediaDetailsExtra extends MediaDetailsBase {
  type: 'extra'
  extra_type: 'trailer' | 'extra' | 'recap' | 'preview'
  paths: {
    player: string
  }
  ad_data?: Record<string, unknown>
}
export function isMediaDetailsExtra(x: MediaDetails): x is MediaDetailsExtra {
  return x.type === 'extra'
}
export interface MovieMedia extends Pick<MediaDetailsCore, 'id'> {
  type: 'movie'
}
export interface EpisodeMedia
  extends Pick<MediaDetailsCore, 'id' | 'episodeNumber' | 'seasonNumber'> {
  type: 'episode'
  seasonNumber: ID
  episodeNumber: ID
}

export type ExtraMedia = Pick<MediaDetailsExtra, 'id' | 'type'>
export type MediaLookupType = MovieMedia | EpisodeMedia | ExtraMedia
export function isExtra(details: any): details is MediaDetailsExtra {
  return details != null && details.type === 'extra'
}
export type MediaDetails = MediaDetailsCore | MediaDetailsExtra

export interface CastPerson {
  name: string
  original_name?: string
  cast_id: ID
  id: ID
  biography?: string
  birthday?: Date
  deathday?: Date
  place_of_birth?: string
  profile: ImageSet
}

export interface CastMember extends CastPerson {
  profile: ImageSet
  character?: string
  credit_id?: string
}

export interface ContentSeason {
  show_id: ID
  air_date?: Date
  episodes?: ContentEpisode[] | null
  episode_count?: number
  id: ID
  name?: string
  overview?: string
  image?: ImageSet
  number?: number
}

export function isID(x: any): x is ID {
  return isNumber(x) || isString(x)
}

export interface ContentEpisode {
  air_date?: Date
  id: ID
  name: string
  overview?: string
  image?: ImageSet
  season?: number
  runtime?: number
  episodeId: ID
  episodeNumber?: number
  media?: MediaDetailsCore[]
}
export declare class Timestamp {
  readonly seconds: number
  readonly nanoseconds: number
  static now(): Timestamp
  static fromDate(date: Date): Timestamp
  static fromMillis(milliseconds: number): Timestamp
  constructor(seconds: number, nanoseconds: number)
  toDate(): Date
  toMillis(): number
  isEqual(other: Timestamp): boolean
  toString(): string
  toJSON(): {
    seconds: number
    nanoseconds: number
  }
  valueOf(): string
}
export interface Genre {
  id: ID
  name: string
}
export interface ContentItem {
  type:
    | 'tv'
    | 'episode'
    | 'movie'
    | 'station'
    | 'track'
    | 'podcast'
    | 'podcastEpisode'
    | 'category'
    | 'image'

  details: string
  parentalRating?:
    | 'g'
    | 'pg'
    | 'pg-13'
    | 'r'
    | 'nc-17'
    | 'tv-y'
    | 'tv-y7'
    | 'tv-g'
    | 'tv-pg'
    | 'tv-14'
    | 'tv-ma'
    | null
  id: ID
  images: MediaImages
  seasons?: ContentSeason[]
  media?: MediaDetails[]
  cast?: CastMember[]
  release?: Date | undefined | null
  paths: Partial<Record<ItemPaths, string>>
  latest_item?: ID
  similar?: ContentItem[]
  title: string
  rating?: string
  description: string
  popularity?: number
  tagline?: string
  genres: Genre[]
  recommendations?: ContentItem[]
  next?: ContentItem
  parent?: ContentItem
  children?: ContentItem[]
  playlist?: ContentItem[]
  overview?: string
}
export function isShowContentItem(x: ContentItem): x is ShowContentItem {
  return x.type === 'tv'
}
export interface ShowContentItem extends ContentItem {
  type: 'tv'
  nextEpisode?: ContentEpisode
  lastEpisode?: ContentEpisode
  firstAirDate?: Date
  nextAirDate?: Date
  lastAirDate?: Date
}

export interface PodcastEpisodeContentItem extends ContentItem {
  type: 'podcastEpisode'
  podcastId: ID
}

export interface RadioStation
  extends Omit<ContentItem, 'tagline' | 'rating' | 'popularity'> {
  type: 'station'
  tagline?: string
  stationOptions: {
    on_demand: number
    pre_gain: number
    last_updated?: Date
    can_skip?: boolean
    can_like?: boolean
    crossfade_seconds?: number
    single_play: boolean
  }
}
export interface Track
  extends Omit<ContentItem, 'tagline' | 'rating' | 'popularity' | 'genres'> {
  type: 'track'
  tagline?: string
  genres?: string[]
  sources?: ContentSource[]
}
export type ContentItemKeys = keyof ContentItem
export type ContentPath = keyof ContentItem['paths']
export const availableContentPaths: ContentPath[] = ['details', 'player']
export type FetchableContentItems = Extract<
  ContentItemKeys,
  'similar' | 'seasons' | 'recommendations'
>

export type VideoTrackingEvents =
  | 'pause'
  | 'play'
  | 'rewind'
  | 'fastforward'
  | 'stop'
  | 'skip'
  | 'progress'
  | 'load_start'
  | 'seeked'
  | 'waiting'
  | 'ended'
export type AllVideoEvents =
  | VideoTrackingEvents
  | 'rate_change'
  | 'rendition_change'
export interface BaseVideoEvent {
  video_id: ID
  video_title: string
  video_position?: number
  video_duration?: number
  video_progress?: number
}
export interface StandardEvent extends BaseVideoEvent {
  video_event: AllVideoEvents
}

export interface VideoRateChangeEvent extends BaseVideoEvent {
  video_event: 'rate_change'
  rate_data: {
    bitrate: number
  }
}

export interface VideoViewEvent {
  video_id: ID
  video_title: string
  progress: '0%' | '25%' | '50%' | '75%' | '100%'
}
// export const VideoViewStates: VideoViewEvent['state'][] = [
//   'playing',
//   'waiting',
//   'completed',
// ]
export const VideoViewProgress: VideoViewEvent['progress'][] = [
  '0%',
  '25%',
  '50%',
  '75%',
  '100%',
]
export interface VideoRenditionChangeEvent extends BaseVideoEvent {
  video_event: 'rendition_change'
  rendition_data: {
    bitrate: number
    width: number
    height: number
    codecSet: string
  }
}
export function isVideoRenditionChangeEvent(
  v: VideoEvent
): v is VideoRenditionChangeEvent {
  return v.video_event === 'rendition_change'
}
export function isVideoRateChangeEvent(
  v: VideoEvent
): v is VideoRateChangeEvent {
  return v.video_event === 'rate_change'
}
export type TrickModeType = 'fastforward' | 'rewind' | null
export type VideoEvent =
  | VideoRateChangeEvent
  | VideoRenditionChangeEvent
  | StandardEvent
export type BaseCardContentItem = Pick<
  ContentItem,
  'title' | 'images' | 'description' | 'id'
>

export interface BasePlayerPayload {
  content: ContentItem
  media: MediaDetails
}
export interface EpisodePlayerPayload extends BasePlayerPayload {
  episode: ContentEpisode
  season?: ContentSeason
}
export function isEpisodePlayerPaylaod(
  x: PlayerPayload
): x is EpisodePlayerPayload {
  return (x as EpisodePlayerPayload).episode !== undefined
}
export type PlayerPayload = BasePlayerPayload | EpisodePlayerPayload
export interface GridParams {
  type: MediaTypes
}
export interface GridContext {
  heroItem?: ContentItem
  params: GridParams
}
export interface TheGridRowData {
  id: number | string
  title: string
  items: ContentItem[]
}
export interface GridResponse {
  requestId: ID
  grid: TheGridRowData[]
  heros?: TheGridRowData
  context: GridContext
}
export interface SimpleGridRow {
  id: ID
  title: string
  subtitle?: string
  items: ContentItem[]
}

export interface TheGridRowData {
  id: number | string
  title: string
  items: ContentItem[]
  cardType?: 'tv' | 'movie'
}

export interface TheGridProps {
  focusKey?: string
  data?: TheGridRowData[] | undefined | null
}
